// client/src/components/SearchBox.js

import React, { useState, useRef, useEffect } from "react";
import { TextField, List, ListItem, ListItemText, Paper } from "@mui/material";
import "./SearchBox.css";

const SearchBox = ({ onLocationSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const subscriptionKey =
    "EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG"; // Replace with your Azure Maps subscription key
  const searchBoxRef = useRef(null); // Ref for detecting clicks outside

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 2) {
      // Trigger search after typing at least 3 characters
      try {
        const response = await fetch(
          `https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&query=${encodeURIComponent(
            query
          )}&subscription-key=${subscriptionKey}`
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching search results: ${response.statusText}`
          );
        }

        const data = await response.json();
        setSuggestions(data.results);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    onLocationSelect(suggestion.position); // Pass location to parent component
    setSearchTerm(suggestion.address.freeformAddress); // Update search box with selected location
    setSuggestions([]); // Clear suggestions
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent form submission if within a form
      event.preventDefault();
      // Clear suggestions on Enter press
      setSuggestions([]);
    }
  };

  // Close suggestions when clicking outside of the search box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setSuggestions([]); // Clear suggestions on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-box-container" ref={searchBoxRef}>
      <TextField
        label="Search location"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown} // Attach the Enter key handler
      />
      {suggestions.length > 0 && (
        <Paper className="suggestions-list">
          <List>
            {suggestions.map((suggestion, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <ListItemText primary={suggestion.address.freeformAddress} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};

export default SearchBox;
