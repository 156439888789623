import React, { useEffect, useRef, useState } from "react";
import * as atlas from "azure-maps-control";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GarageIcon from '@mui/icons-material/Garage';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';

import SearchBox from "./SearchBox.jsx";
import "./AzureMapComponent.css";
import Navbar from "../NavBar/Navbar.jsx";

const mapSubscriptionKey =
  "EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG";

const AzureMapComponent = () => {
  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const dataSourceRef = useRef(null);
  const initializedRef = useRef(false);
  const [pins, setPins] = useState([]);
  const [selectedPin, setSelectedPin] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [statusFilters, setStatusFilters] = useState({
    Green: false,
    Yellow: false,
    Red: false,
    GatedFenced: false,
    Garage: false,
    Fee: false,
    NotRegistered: false, // New filter
  });
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

  const socketRef = useRef(null);

  const handleStatusFilterChangeButton = (filterName) => {
    setStatusFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedPin(null);
  };

  const handleLocationSelect = (position) => {
    if (mapRef.current) {
      mapRef.current.setCamera({
        center: [position.lon, position.lat],
        zoom: 12,
      });
    }
  };

  // Throttle function to prevent rapid resizing
  const throttle = (func, delay) => {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      return func(...args);
    };
  };

  // Throttled resize function
  const throttledResize = throttle(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, 100); // Adjust delay as needed

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen(); // Safari
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen(); // IE11
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE11
      }
    }

    // Delay resize slightly after toggling full screen
    setTimeout(() => {
      throttledResize();
    }, 300); // Adjust delay if needed
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement === containerRef.current);
      throttledResize(); // Resize map on full-screen change
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${hostPath}/api/GetAllSales`, {
        method: 'GET',
        headers: {
          "x-functions-key": `${hostHeader}`,
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);
      setPins(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRefreshClick = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData(); // Initial fetch on component mount
    const intervalId = setInterval(fetchData, 21600000); // Poll every 6 hours

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const isTrue = (value) => {
    return value && value.trim().toLowerCase() === "true";
  };

  const pinImageMappings = [
    // Corrected filter conditions
    {
      imageId: "green-pin-garage-fence-fee",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "red-pin-garage-fence-fee",
      match: (pin) =>
        pin.StatusReason === "2" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "yellow-pin-garage-fence-fee",
      match: (pin) =>
        pin.StatusReason === "1" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "red-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "2" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "yellow-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "green-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "green-pin-garage",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "red-pin-garage",
      match: (pin) =>
        pin.StatusReason === "2" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "yellow-pin-garage",
      match: (pin) =>
        pin.StatusReason === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "red-pin",
      match: (pin) =>
        pin.StatusReason === "2" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "yellow-pin",
      match: (pin) =>
        pin.StatusReason === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "green-pin",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "green-pin-garage-fence",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "red-pin-garage-fence",
      match: (pin) =>
        pin.StatusReason === "2" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "yellow-pin-garage-fence",
      match: (pin) =>
        pin.StatusReason === "1" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "yellow-pin-fence",
      match: (pin) =>
        pin.StatusReason === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "green-pin-fence",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "red-pin-fence",
      match: (pin) =>
        pin.StatusReason === "2" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        (!isTrue(pin.Fee) || pin.Fee === ""),
    },
    {
      imageId: "green-pin-fence-fee",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "red-pin-fence-fee",
      match: (pin) =>
        pin.StatusReason === "2" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "yellow-pin-fence-fee",
      match: (pin) =>
        pin.StatusReason === "1" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced) &&
        isTrue(pin.Fee),
    },
    {
      imageId: "green-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "green-pin-garage-fee-inactive",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "0" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "yellow-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "red-pin-garage-fee",
      match: (pin) =>
        pin.StatusReason === "2" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === "") &&
        isTrue(pin.Fee),
    },
    {
      imageId: "green-pin-garage-fence-inactive",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "0" &&
        isTrue(pin.garage) &&
        isTrue(pin.GatedOrFenced),
    },
    {
      imageId: "green-pin-inactive",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "0" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === ""),
    },
    {
      imageId: "green-pin-fence-inactive",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "0" &&
        (!isTrue(pin.garage) || pin.garage === "") &&
        isTrue(pin.GatedOrFenced),
    },
    {
      imageId: "green-pin-garage-inactive",
      match: (pin) =>
        pin.StatusReason === "100000000" &&
        pin.Status === "1" &&
        isTrue(pin.garage) &&
        (!isTrue(pin.GatedOrFenced) || pin.GatedOrFenced === ""),
    },
  ];

  useEffect(() => {
    if (!initializedRef.current) {
      const azureMap = new atlas.Map("azureMapContainer", {
        center: [-122.431297, 37.773972], // Default center (San Francisco City)
        zoom: 8,
        view: "Auto",
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: mapSubscriptionKey,
        },
        showLogo: false, // Disable Azure logo
        showFeedbackLink: false, // Disable Feedback link
        showCopyright: false, // Disable copyright text
        language: "en-US", // Optional: Set the language, e.g., English
      });
      azureMap.events.add("ready", () => {
        const dataSource = new atlas.source.DataSource(null, {
          cluster: true,
          clusterRadius: 25,
        });

        azureMap.sources.add(dataSource);
        dataSourceRef.current = dataSource;

        const loadImageToMap = (map, id, url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
              map.imageSprite.add(id, img).then(resolve).catch(reject);
            };
            img.onerror = (err) => {
              console.error(`Failed to load image ${id} from ${url}`, err);
              reject(err);
            };
            img.src = url;
          });
        };

        const uniqueImageIds = Array.from(
          new Set(pinImageMappings.map((mapping) => mapping.imageId))
        );

        Promise.all(
          uniqueImageIds.map((imageId) =>
            loadImageToMap(azureMap, imageId, `/icons/${imageId}.png`)
          )
        )
          .then(() => {
            azureMap.layers.add(
              new atlas.layer.BubbleLayer(dataSource, null, {
                filter: ["has", "point_count"],
                radius: ["step", ["get", "point_count"], 10, 100, 15, 750, 20],
                color: [
                  "step",
                  ["get", "point_count"],
                  "lightblue",
                  100,
                  "green",
                  750,
                  "red",
                ],
                strokeWidth: 2,
                strokeColor: "white",
              })
            );

            azureMap.layers.add(
              new atlas.layer.SymbolLayer(dataSource, null, {
                filter: ["has", "point_count"],
                iconOptions: { image: "none" },
                textOptions: {
                  textField: "{point_count_abbreviated}",
                  offset: [0, 0.4],
                  color: "black",
                  font: ["StandardFont-Bold"],
                  size: 12,
                },
              })
            );

            const symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              filter: ["!", ["has", "point_count"]],
              iconOptions: {
                image: ["get", "image"],
                allowOverlap: true,
                size: 0.7,
              },
            });

            azureMap.layers.add(symbolLayer);

            azureMap.events.add("click", symbolLayer, (e) => {
              if (e.shapes && e.shapes.length > 0) {
                const shape = e.shapes[0];
                let properties;

                if (typeof shape.getProperties === "function") {
                  properties = shape.getProperties();
                } else {
                  properties = shape.properties;
                }

                setSelectedPin(properties);
                setDialogOpen(true);
              }
            });

            initializedRef.current = true;
            mapRef.current = azureMap;
          })
          .catch((err) => {
            console.error("Error loading images:", err);
          });
      });
    }
  }, []);

  useEffect(() => {
    if (dataSourceRef.current) {
      if (pins.length > 0) {
        populatePins(pins);
      } else {
        dataSourceRef.current.clear();
      }
    }
  }, [pins, statusFilters]);

  const populatePins = (pinsData) => {
    const anyStatusSelected = Object.keys(statusFilters)
      .filter((key) => key !== "GatedFenced" && key !== "Fee" && key !== "Garage" && key !== "NotRegistered")
      .some((key) => statusFilters[key]);

    const isGatedFencedFilterActive = statusFilters.GatedFenced;
    const isFeeFilterActive = statusFilters.Fee;
    const isGarageFilterActive = statusFilters.Garage;
    const isNotRegisteredFilterActive = statusFilters.NotRegistered;

    const features = pinsData
      .filter((pin) => {
        const statusReasonValue = pin.StatusReason;
        const statusValue = pin.Status;
        const gatedFenced = isTrue(pin.GatedOrFenced);
        const feeValue = isTrue(pin.Fee);
        const garageValue = isTrue(pin.garage);

        // Corrected mapping of StatusReason values to colors
        let statusMatch = true;
        if (anyStatusSelected) {
          statusMatch = false;
          if (statusFilters.Green && statusReasonValue === "100000000") {
            statusMatch = true;
          }
          if (statusFilters.Yellow && statusReasonValue === "1") {
            statusMatch = true;
          }
          if (statusFilters.Red && statusReasonValue === "2") {
            statusMatch = true;
          }
        }

        let gatedFencedMatch = true;
        if (isGatedFencedFilterActive) {
          gatedFencedMatch = gatedFenced;
        }

        let feeMatch = true;
        if (isFeeFilterActive) {
          feeMatch = feeValue;
        }

        let garageMatch = true;
        if (isGarageFilterActive) {
          garageMatch = garageValue;
        }

        let notRegisteredMatch = true;
        if (isNotRegisteredFilterActive) {
          notRegisteredMatch = statusValue === "0";
        }

        return statusMatch && gatedFencedMatch && feeMatch && garageMatch && notRegisteredMatch;
      })
      .map((pin) => {
        const latValue = pin.Latitude;
        const lonValue = pin.Longitude;

        if (latValue == null || lonValue == null) {
          console.warn(
            `Skipping pin with missing coordinates: ${pin.PropertyAddress || "Unknown"}`
          );
          return null;
        }

        const lat = parseFloat(latValue.toString().trim());
        const lon = parseFloat(lonValue.toString().trim());

        if (isNaN(lat) || isNaN(lon)) {
          console.error(`Invalid coordinates for pin: ${latValue}, ${lonValue}`);
          return null;
        }

        const imageId = getImageFromStatus(pin);

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lon, lat],
          },
          properties: {
            ...pin,
            image: imageId,
          },
        };
      })
      .filter(Boolean);

    dataSourceRef.current.clear();
    dataSourceRef.current.add(features);
  };

  // Correctly map StatusReason values to the appropriate icon colors
  const getImageFromStatus = (pin) => {
    for (let mapping of pinImageMappings) {
      if (mapping.match(pin)) {
        return mapping.imageId;
      }
    }
    // Default image if no match is found
    return "yellow-pin";
  };

  // Helper functions to determine pin status
  const isWon = (pin) => pin.StatusReason === "100000000";
  const isInProgress = (pin) => pin.StatusReason === "1";
  const isLost = (pin) => pin.StatusReason === "2";

  return (
    <>
      <Navbar />
      <div className="map-fullscreen-container" ref={containerRef}>
        {/* Filters on top left */}
        <div className="filters-overlay">
          <div className="filters">
            <Button
              className={`filter-button green-filter ${statusFilters.Green ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("Green")}
              startIcon={<img src="/icons/won-icon.png" alt="Won" className="icon" />}
            >
              Won
            </Button>
            <Button
              className={`filter-button yellow-filter ${statusFilters.Yellow ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("Yellow")}
              startIcon={<img src="/icons/in-progress-icon.png" alt="In Progress" className="icon" />}
            >
              In Progress
            </Button>
            <Button
              className={`filter-button red-filter ${statusFilters.Red ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("Red")}
              startIcon={<img src="/icons/lost-icon.png" alt="Lost" className="icon" />}
            >
              Lost
            </Button>
            <Button
              className={`filter-button gated-filter ${statusFilters.GatedFenced ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("GatedFenced")}
              startIcon={<LockIcon className="icon" />}
            >
              Gated/Fenced
            </Button>
            <Button
              className={`filter-button fee-filter ${statusFilters.Fee ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("Fee")}
              startIcon={<AttachMoneyIcon className="icon" />}
            >
              Fee
            </Button>
            <Button
              className={`filter-button garage-filter ${statusFilters.Garage ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("Garage")}
              startIcon={<GarageIcon className="icon" />}
            >
              Garage
            </Button>
            <Button
              className={`filter-button inactive-filter ${statusFilters.NotRegistered ? 'active' : ''}`}
              onClick={() => handleStatusFilterChangeButton("NotRegistered")}
              startIcon={<BlockIcon className="icon" />}
            >
              Not Registered
            </Button>
          </div>
        </div>
        {/* Search box on top right */}
        <div className="search-overlay">
          <SearchBox onLocationSelect={handleLocationSelect} />
        </div>
        {/* Map container */}
        <div id="azureMapContainer" className="map-container"></div>
        {/* Refresh button at bottom center */}
        <div className="refresh-button-overlay">
          <Button variant="contained" color="primary" onClick={handleRefreshClick}>
            Refresh Data
          </Button>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 16,
            overflow: 'hidden',
            position: 'fixed',
            bottom: '16px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 0,
            padding: '8px 16px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}
        BackdropProps={{
          style: { backgroundColor: 'transparent' },
        }}
      >
        <DialogContent style={{ display: 'flex', padding: 0 }}>
          {selectedPin && (
            <>
              {/* Left side: Larger Image container */}
              <div style={{ width: '40%', position: 'relative' }}>
                <img
                  src="/icons/dummy-image.png"
                  alt="Dummy"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>

              {/* Right side: Property details with increased font sizes and spacing */}
              <div style={{ width: '60%', padding: '16px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {/* Close button at the top right */}
                <IconButton
                  onClick={() => setDialogOpen(false)}
                  style={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>

                <Typography
                  variant="h6"
                  style={{ fontWeight: 'bold', fontSize: '1.2rem', lineHeight: 1.6 }}
                >
                  {`${selectedPin.PropertyAddress}, ${selectedPin.State} ${selectedPin.Zip}`}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: '1rem', color: '#555', lineHeight: 1.6 }}
                  gutterBottom
                >
                  {`Number of Parking Spaces: ${selectedPin.NumberOfParkingSpaces || 'N/A'}`}
                </Typography>

                {/* Property Type */}
                <Typography
                  variant="body2"
                  style={{ fontSize: '1rem', color: '#555', lineHeight: 1.6 }}
                  gutterBottom
                >
                  {`Property Type: ${selectedPin.PropertyType || 'N/A'}`}
                </Typography>

                {/* Icons row with increased spacing and centered vertically */}
                <div style={{ display: 'flex', gap: '12px', marginTop: '12px', alignItems: 'center' }}>
                  {isWon(selectedPin) && (
                    <img src="/icons/won-icon.png" alt="Won" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isInProgress(selectedPin) && (
                    <img src="/icons/in-progress-icon.png" alt="In Progress" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isLost(selectedPin) && (
                    <img src="/icons/lost-icon.png" alt="Lost" style={{ width: '24px', height: '24px' }} />
                  )}
                  {isTrue(selectedPin.garage) && (
                    <Typography variant="body2" style={{ fontSize: '1.4rem' }}>G</Typography>
                  )}
                  {isTrue(selectedPin.GatedOrFenced) && (
                    <LockIcon style={{ fontSize: 24 }} />
                  )}
                  {isTrue(selectedPin.Fee) && (
                    <AttachMoneyIcon style={{ fontSize: 24 }} />
                  )}
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>


    </>
  );
};

export default AzureMapComponent;
