import React, { useState } from 'react';
import '../styles/Header.css';
import logo from '../assets/GeoParking.png';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const envname =  process.env.REACT_APP_ENV_TXT;

  const handleSignUpClick = (event) => {
    if (event.button === 0) {
      navigate('/signup');
    }
  };

  const handleLoginClick = (event) => {
    if (event.button === 0) {
      navigate('/login');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <header className="header">
         <div className="logo-container">
      <div className="logo">
        <img src={logo} alt="GeoParking Technologies" />
      </div>
      <span className="staging-text">{envname}</span>
      </div>
      <nav className={isSidebarOpen ? 'nav-links sidebar' : 'nav-links'}>
        <ul>
          <li>
            <Link to="home" smooth={true} duration={500} onClick={closeSidebar}>
              Home
            </Link>
          </li>
          <li>
            <Link to="who-are-we" smooth={true} duration={500} onClick={closeSidebar}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="contact-us" smooth={true} duration={500} onClick={closeSidebar}>
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="auth-buttons">
          <a href="/signup" target="_blank" className="sign-up" onMouseDown={handleSignUpClick}>Sign Up</a>
          <a href="/login" target="_blank" className="login-1" onMouseDown={handleLoginClick}>Login</a>
        </div>
      </nav>
      <div className={`menu-toggle ${isSidebarOpen ? 'open' : ''}`} id="nav-icon3" onClick={toggleSidebar}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
